
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'src/app/models/message.model';
import { Product } from 'src/app/models/product.model';
import { ServiceHttpService } from 'src/app/services/service-http.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  product: Product;
  products: any[] = [];
  base_url: string;
  category_name: string;
  subcategory_name: string;
  id_product: number;
  products_url: string;
  session: any = {
    id_user_access: 0
  };
  message_text: Message;
  name_text: string = '';
  images_products: any = [];
  base_url_images: string = '';
  constructor(private service: ServiceHttpService, public router: Router, private route: ActivatedRoute) {
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'visible';
    loading_image.style.position = 'initial';
    this.products_url = service.BASE_URL + "upload/product/";
    this.base_url = service.BASE_URL;//+ "upload/product/";
    this.base_url_images = service.BASE_URL;//+ "upload/product/others/";
    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
    this.product = {
      category_id: 0,
      category_name: '',
      currency_code: '',
      currency_id: 0,
      currency_name: '',
      favorite: false,
      product_description: '',
      product_id: 0,
      product_image: '',
      product_name: '',
      product_price: 0,
      product_quantity: 0,
      product_status: '',
      product_video: '',
      quantity: 0,
      tag: '',
      tax: 0,
      sub_category_id: 0
    };
    this.message_text = {
      color: 'alert-warning',
      text: '',
      visible: false
    };
    let s = this.route.url.subscribe((s2) => {
      try {
        this.id_product = Number(s2[4].path);
        this.category_name = s2[0].path;
        this.subcategory_name = s2[1].path;
        this.name_text = s2[2].path;
      } catch (error) {
        this.id_product = 0;
      }

    });

  }

  ngOnInit(): void {
    this.getProduct();
    let id_user_access = this.service.getStorage("id_user_access");
    id_user_access.then((vars) => {

      if (vars == null || vars == 'null') {
        this.session = {
          id_user_access: 0
        }
      }
      else {
        this.session = {
          id_user_access: vars
        }
      }
      console.log(this.session);
    });

  }
  async getProduct() {
    let content = this;
    var request = await this.service.getV1(this.service.GET_PRODUCT + this.id_product);
    request.subscribe((data: any) => {
      content.product = data;
      content.product.product_image = content.base_url + "upload/product/" + content.product.product_image;
      this.loadImages();
      this.getCategories();
    });
  }
  changeImage(item) {
    this.product.product_image = item.url_product;
  }
  loadImages() {
    this.images_products = [];
    let item = {
      url_product: this.product.product_image,
      is_video: false
    };
    this.images_products.push(item);
    var subcate = this.service.getV1(this.service.GET_IMAGE_PRODUCT + this.product.product_id);
    subcate.subscribe(
      res => {
        let array = res as any[];
        array.forEach(element => {
          let item2 = {
            url_product: this.base_url + "upload/product/others/" + element.url_product,
            is_video: false
          };
          this.images_products.push(item2);
        });

      });
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'hidden';
    loading_image.style.position = 'absolute';
    window.scrollTo(0, 0);

  }
  plus() {
    let count_item = document.getElementById('count_item') as any;
    if (this.product.product_quantity >= Number(count_item.value) + 1 && Number(count_item.value) >= 1) {
      count_item.value = Number(count_item.value) + 1;
    }
  }
  minus() {
    let count_item = document.getElementById('count_item') as any;
    if (this.product.product_quantity >= Number(count_item.value) - 1 && Number(count_item.value) > 1) {
      count_item.value = Number(count_item.value) - 1;
    }
  }
  async addCard() {
    let count_item = document.getElementById('count_item') as any;
    let count = count_item.value;
    console.log(this.session);
    if (this.session.id_user_access == null || this.session.id_user_access == 'null' || this.session.id_user_access == 0) {

      this.message('No iniciaste sesión, debe iniciar sesión para continuar', 'alert-warning');
      return;
    }

    var formData = new FormData();
    formData.append("id_product_card", this.product.product_id + "");
    formData.append("id_user_card_product", this.session.id_user_access + "")
    formData.append("count_product", count + "");
    await this.service.postV2(this.service.CREATE_PRODUCT_CARD, formData).then((data) => {

      if (data == "-1") {
        this.message("El producto ya existe en el carrito", "alert-warning");
      }
      else if (data == "0") {
        this.message("No pudo agregar el producto", "alert-danger");
      }
      else {
        this.message("Producto agregado al carrito", "alert-success");
      }
    });
  }
  message(text, color) {
    this.message_text.text = text;
    this.message_text.color = color;
    this.message_text.visible = true;
  }
  closeMessage() {
    this.message_text = {
      visible: false,
      color: '',
      text: ''
    }
  }
  shuffle(array) {
    var ctr = array.length, temp, index;
    while (ctr > 0) {
      index = Math.floor(Math.random() * ctr);
      ctr--;
      temp = array[ctr];
      array[ctr] = array[index];
      array[index] = temp;
    }
    return array;
  }
  async getCategories() {
    let content = this;
    content.products = [];
    var request_subca = await this.service.getV1(this.service.GET_PRODUCTS_CATEGORY + this.product.category_id + "&page=1");
    request_subca.subscribe((data: any[]) => {
      content.products = this.shuffle(data);
      content.products.forEach(element => {
        element.image = content.products_url + element.product_image;
        element.thumbImage = content.products_url + element.product_image;
        element.title = element.product_name;
      });
      console.log(content.products);
    });
  }
  changeVideo() {

    let iframe = document.getElementById('videoplay') as any;
    iframe.innerHTML = '<iframe class="embed-responsive-item" id="video" allowscriptaccess = "always" allow = "autoplay" src="' + this.product.product_video + '"></iframe>';

  }
  slider(e) {
    let item = (this.products[e]);//index
    console.log(item);
    this.router.navigate(['/categorias/' + item.category_name.replace('/', '') + '/' + this.subcategory_name.replace('/', '') + '/' + item.product_name.replace('/', '') + '/producto/' + item.product_id], {});
    this.id_product = item.product_id;
    this.getProduct();
  }
}
