import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { ServiceHttpService } from 'src/app/services/service-http.service';
@Component({
  selector: 'app-products',
  templateUrl: './uso-cookies.component.html',
  styleUrls: ['./uso-cookies.component.scss']
})

export class UsoCookiesComponent implements OnInit {


  constructor(private service: ServiceHttpService, public router: Router, private route: ActivatedRoute) {

    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'hidden';
    loading_image.style.position = 'absolute';
  }

  ngOnInit(): void {

  }
}
