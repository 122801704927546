import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BuscadorComponent } from './pages/categories/buscador/buscador.component';
import { ContactComponent } from './pages/categories/contact/contact.component';
import { HomeRoutingModule } from './pages/categories/home/home-routing.module';
import { PoliticasConfidencialidadComponent } from './pages/categories/politicas-confidencialidad/politicas-confidencialidad.component';
import { PoliticasPrivacidadComponent } from './pages/categories/politicas-privacidad/politicas-privacidad.component';
import { UsoCookiesComponent } from './pages/categories/uso-cookies/uso-cookies.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'categorias',
    pathMatch: 'full',
  },
  { path: "categorias", loadChildren: () => import('./pages/categories/home/home.module').then(m => m.HomeModule) },
  { path: "cuenta", loadChildren: () => import('./pages/categories/account/account.module').then(m => m.AccountModule) },
  {
    path: 'buscador', component: BuscadorComponent, data: { preload: true }

  },
  {
    path: 'contactos', component: ContactComponent, data: { preload: true }

  }
  , {
    path: 'politicas-confidencialidad', component: PoliticasConfidencialidadComponent, data: { preload: true }
  }
  , {
    path: 'politicas-privacidad', component: PoliticasPrivacidadComponent, data: { preload: true }
  }
  , {
    path: 'uso-de-cookies', component: UsoCookiesComponent, data: { preload: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
