import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { ServiceHttpService } from 'src/app/services/service-http.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {

  @HostListener('window:scroll') onScroll(e): void {
    this.getYPosition();
  }
  subcategory: number;
  products: Product[] = [];
  base_url: string = '';
  category_name: string = '';
  sub_category_name: string = '';
  name_text: string = '';
  listener;
  paginador_index = 400;
  page_paginate: number = 1;
  constructor(private service: ServiceHttpService, public router: Router, private route: ActivatedRoute) {

    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'visible';
    loading_image.style.position = 'initial';
    this.base_url = service.BASE_URL + "upload/product/";
    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
    let s = this.route.url.subscribe((s2) => {
      try {
        this.subcategory = Number(s2[3].path);
        this.category_name = s2[0].path;
        this.sub_category_name = s2[1].path;
        this.name_text = s2[2].path;
      } catch (error) {
        this.subcategory = 0;
      }

    });
  }
  async getYPosition() {
    if (this.paginador_index < window.scrollY) {
      this.paginador_index = this.paginador_index + 400;
      this.page_paginate++;
      let content = this;
      var request = await this.service.getV1(this.service.GET_PRODUCTS + this.subcategory + '&page=' + this.page_paginate);
      request.subscribe((data: any[]) => {
        data.forEach(element => {
          content.products.push(element);
        });
      });
    }
  }
  ngOnInit(): void {
    this.getProducts();
  }
  async getProducts() {
    let content = this;
    content.products = [];
    var request = await this.service.getV1(this.service.GET_PRODUCTS + this.subcategory + '&page=' + this.page_paginate);
    request.subscribe((data: any[]) => {
      let loading_image = document.getElementById('loading_image') as any;
      loading_image.style.visibility = 'hidden';
      loading_image.style.position = 'absolute';
      window.scrollTo(0, 0);
      data.forEach(element => {
        content.products.push(element);
      });

    });
  }

  gotoProduct(item) {
    this.router.navigate(['/categorias/' + this.category_name.replace('/', '') + '/' + item.sub_category_name.replace('/', '') + '/' + item.product_name.replace('/', '') + '/producto/' + item.product_id], {});
  }
}
//routerLink='/categorias/{{category_name}}/{{item.sub_category_name}}/{{item.product_name}}/producto/{{item.product_id}}'