import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/models/message.model';
import { ServiceHttpService } from 'src/app/services/service-http.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  message_text: Message = {
    color: 'alert-warning',
    text: '',
    visible: false
  };
  constructor(private service: ServiceHttpService) {
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'hidden';
    loading_image.style.position = 'absolute';
    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
  }

  ngOnInit(): void {
  }
  async sutbmit() {
    let name = document.getElementById('name') as any;
    let email = document.getElementById('email') as any;
    let message = document.getElementById('message') as any;
    if (name.value == '') {
      this.message("Escribe tu nombre", "alert-warning");
      return;
    }
    if (email.value == '') {
      this.message("Escribe tu correo", "alert-warning");
      return;
    }
    if (message.value == '') {
      this.message("Escribe un mensaje", "alert-warning");
      return;
    }

    var client = new FormData();
    client.append("name", name.value + "");
    client.append("email", email.value + "");
    client.append("message", message.value + "");
    console.log(client);
    await this.service.postV2(this.service.POST_CONTACT, client).then((model) => {
      console.log(model);
      this.message("Mensaje enviado!!", "alert-success");
      name.value = "";
      email.value = "";
      message.value = "";
    });

  }
  message(text, color) {
    this.message_text.text = text;
    this.message_text.color = color;
    this.message_text.visible = true;
  }
}
