import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Subscription } from "rxjs";
import { Observable, of, from } from 'rxjs';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ServiceHttpService {
  public URL_APP_ANDROID = 'https://play.google.com/store/apps/details?id=com.hostbo.suplementapp';
  public URL_APP_IOS = 'https://apps.apple.com/us/app/hostingbo-net/id1543890668';

  public BASE_URL = "https://suplementapp.com.bo/admin/";
  public GET_CATEGORY = this.BASE_URL + "api/api.php?get_category";

  public GET_SUBCATEGORIES = this.BASE_URL + "api/api.php?get_subcategory";
  public GET_SUBCATEGORY = this.BASE_URL + "api/api.php?get_subcategories=";
  public GET_ACCOUNT = this.BASE_URL + "api/api.php?login=";
  public CHANGE_PASSWORD = this.BASE_URL + "api/api.php?update_password=";
  public POST_REGISTER = this.BASE_URL + "api/api.php?create_login=";
  public POST_UPDATE_USER = this.BASE_URL + "api/api.php?update_account";
  public GET_DATA_USER = this.BASE_URL + "api/api.php?get_user_data";
  public GET_IMAGE_PRODUCT = this.BASE_URL + "api/api.php?get_product_images=";
  public GET_PRODUCTS = this.BASE_URL + "api/api.php?category_id=";
  public GET_CUPON = this.BASE_URL + "api/api.php?cupon=";
  public GET_BANNERS = this.BASE_URL + "api/banners.php";
  public GET_PRODUCT = this.BASE_URL + "api/api.php?product_id=";
  public GET_PRODUCTOS_RECIENTES = this.BASE_URL + "/api/api.php?get_recent";
  public GET_PRODUCTS_CATEGORY = this.BASE_URL + "/api/api.php?get_product_category=";
  public GET_TAX_CURRENCY = this.BASE_URL + "api/api.php?get_tax_currency";
  public GET_HELP = this.BASE_URL + "api/api.php?get_help";
  public GET_FACTURE = this.BASE_URL + "api/get_facture_generate.php";
  public GET_SHIPPING = this.BASE_URL + "api/api.php?get_shipping";
  public GET_STATUS_REQUEST = this.BASE_URL + "api/estado_orden.php?codigo=";
  public POST_ORDER = this.BASE_URL + "api/api.php?post_order";
  public POST_UPDATE_ORDER = this.BASE_URL + "api/update_pago.php";
  public POST_UPDATE_FILE = this.BASE_URL + "api/uploadVolley.php";
  public POST_CANCEL_ORDER = this.BASE_URL + "api/api.php?cancel_order";
  public GET_COMPROBANTES = this.BASE_URL + "api/get_facture_generate.php?comprobantes";
  public UPDATE_IDQR = this.BASE_URL + "api/api.php?update_idqr";
  public CREATE_PRODUCT_CARD = this.BASE_URL + "api/api.php?create_product";
  public GET_CARD_PRODUCTS = this.BASE_URL + "api/api.php?get_card_products";
  public REMOVE_CARD_PRODUCTS = this.BASE_URL + "api/api.php?remove_card_product";
  public UPDATE_CARD_PRODUCT = this.BASE_URL + "api/api.php?update_card_product";
  public GET_USER = this.BASE_URL + "api/api.php?getuser";
  public POST_CONTACT = this.BASE_URL + "api/api.php?contact";
  public NOMBRE_NEGOCIO = "SuplementApp";
  public GET_COUNTRIES = "https://restcountries.eu/rest/v2/region/Americas";

  public urlApi = "https://pagosonlineqr.com/admin20/api/";
  public saveApi = this.urlApi + "api.php?payment";
  public saveClientApi = this.urlApi + "api.php?client";
  public setPayment = this.urlApi + "api.php?set_payment";
  data: any;
  errorMessage: string;
  type_pago: any;
  update_file: any;
  timepo1: any;
  timepo2: any;
  constructor(private http: HttpClient) { }

  getV1(url): Observable<String[]> {
    return this.http.get<String[]>(url).pipe(
      catchError(e => throwError(e))
    );
  }

  postV1(data, url): Observable<String[]> {
    return this.http.post<String[]>(url, data, { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(
      catchError(e => throwError(e))
    );
  }
  postV2(url, model) {
    return new Promise(resolve => {
      var update_file = new XMLHttpRequest();
      update_file.open("POST", url, true);
      update_file.onreadystatechange = function () { // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          resolve((this.response));
        }
      }
      update_file.send(model);
    });
  }
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
  //storage
  async getStorage(KEY): Promise<any> {
    return localStorage.getItem(KEY);
  }
  async setStorage(KEY, value): Promise<any> {
    return await this.getStorage(KEY).then((vals: any) => {
      return localStorage.setItem(KEY, value);
      //return this.storage.set(KEY, value);
    });
  }
  //delivery send

  sendOrden(form) {
    this.data = ("email=" + form.email);
    this.append("email", form.email);
    this.append("name", form.name);
    this.append("code", form.code);
    this.append("phone", form.phone);
    this.append("address", form.address);
    this.append("order_list", form.order_list);
    this.append("order_total", form.order_total);
    this.append("comment", form.comment);
    this.append("player_id", form.player_id);
    this.append("date", form.date);
    this.append("server_url", form.server_url);
    this.append("lista_prod_elegidos", form.lista_prod_elegidos);
    this.append("costo_envio", form.costo_envio);
    this.append("lati", form.lati);
    this.append("longi", form.longi);
    this.append("shipping", form.shipping);
    this.append("cuidad", form.cuidad);
    this.append("municipio", form.cuidad);
    this.append("nit", form.nit);
    this.append("razonsocial", form.razonsocial);
    this.append("unidadedificio", form.unidadedificio);
    this.append("city", form.city);
    this.append("estadoregion", form.estadoregion);
    this.append("codigopostal", form.codigopostal);
    this.append("country", form.country);
    this.append("id_cupon", form.id_cupon);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", this.POST_ORDER, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onreadystatechange = function () { // Call a function when the state changes.
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      }
      else {
      }
    }
    xhr.send(this.data);
    this.updatePago(form);

  }
  private append(key, value) {
    this.data += ("&" + key + "=" + value);
  }
  private appendUpdate(key, value) {
    this.type_pago += ("&" + key + "=" + value);
  }
  private updatePago(form) {

    this.type_pago = ("codigo=" + form.code);
    this.appendUpdate("tipo_pago", form.tipo_pago);
    this.appendUpdate("exito_pago", "0");
    let time = 3;
    const timeValue = setInterval((interval) => {
      time = time - 1;
      if (time <= 0) {
        var post_udpate = {
          codigo: form.code,
          tipo_pago: form.tipo_pago,
          exito_pago: "0"
        }
        var update_tipo_pago = new XMLHttpRequest();
        update_tipo_pago.open("POST", this.POST_UPDATE_ORDER, true);
        update_tipo_pago.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        update_tipo_pago.onreadystatechange = function () { // Call a function when the state changes.
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          }
          else {
          }
        }
        update_tipo_pago.send(JSON.stringify(post_udpate));
        clearInterval(timeValue);
        this.updatePago2(form);
      }
    }, 1000);
  }
  updatePago2(form) {
    if (form.image != null && form.tipo_pago == '1') {
      let time = 3;
      const timeValue = setInterval((interval) => {
        time = time - 1;
        if (time <= 0) {
          var post_udpate = {
            codigo: form.code,
            image: form.image,
            name: form.code
          }
          var update_file = new XMLHttpRequest();
          update_file.open("POST", this.POST_UPDATE_FILE, true);
          update_file.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

          update_file.onreadystatechange = function () { // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            }
            else {
            }
          }
          update_file.send(JSON.stringify(post_udpate));
          clearInterval(timeValue);
        }
      }, 1000);

    }
  }
}
