<nav class="navbar navbar-expand-lg navbar-light bg-light nabvar-margin">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <a class="navbar-brand" routerLink='/categorias' style="margin-left: auto;">
      <img src="assets/logo.png" style="    height: 40px;
    width: 130px;" height="auto" class="d-inline-block align-top" alt="">
    </a>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" style="color: white;" routerLink='/categorias'>Inicio <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" style="color: white;" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Productos
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

          <li class="dropdown-submenu" *ngFor="let item of categories;">
            <a class="dropdown-item dropdown-toggle" style="color: black;">{{item.category_name}}</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item mouse"
                  routerLink='/categorias/{{item.category_name}}/{{sub_item.name}}/productos/{{sub_item.sub_category_id}}'
                  target="_blank" style="color: black;"
                  *ngFor="let sub_item of item.subcategories;">{{sub_item.name}}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink='/contactos' style="color: white;">Contactos</a>
      </li>
    </ul>
    <div class="form-inline my-2 my-lg-0" style="margin-right: inherit;">

      <div class="row">
        <div class="col-8">
          <input class="form-control" routerLink='/buscador' id="buscar_id" type="search" placeholder="Buscar"
            aria-label="Search">
        </div>
        <div class="col-4">
          <button class="btn btn-outline-success" style="background-color: #f5bd0b;color:white"
            type="submit">Buscar</button>
        </div>
      </div>



      <button class="btn btn-sm my-2 my-sm-0 float-right" [disabled]="session.email == null" (click)="goCard()"
        style="color: white;">Carrito <i class="fa fa-shopping-cart"></i> <i
          class="item-circle">{{count_card}}</i></button>
      <li class="nav-item dropdown btn btn-sm my-2 my-sm-0 ">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{session.email ==
          null?'Cuenta':session.email}}&nbsp;<i class="fa fa-user" style="color: #f5bd0b;"></i>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="sessionAccess()" style="color: black;">{{session.email ==
            null?'iniciar
            sesión':'Mi perfil'}}</a>
          <a *ngIf="session.email != null" class="dropdown-item" style="color: black;">Mi pedidos</a>
          <div *ngIf="session.email != null" class="dropdown-divider"></div>
          <a *ngIf="session.email != null" class="dropdown-item" (click)="closeSesion()" style="color: black;">Salir</a>
        </div>
      </li>
      <div class="" style="width: 4rem;"></div>
      <button hidden class="btn btn-sm my-2 my-sm-0 float-right" (click)="sessionAccess()">{{session.email ==
        null?'iniciar
        sesión':session.email}}&nbsp;<i class="fa fa-user" style="color: #f5bd0b;"></i></button>
    </div>
  </div>
</nav>



<div align="center" id="loading_image" class="loadingimage">
  <img src="assets/loading2.gif" style="z-index: inherit;" width="auto" height="auto">
</div>

<router-outlet>
</router-outlet>

<footer class=" footer text-center" id="footer">
  <div class="card-body" style="background-color: #fafafa;">
    <div class="row" style="background-color: #fafafa;padding: 1rem;border-bottom: 1px solid #313D52;">
      <div class="col-4 ui-footer-col ui-footer-col-first">
        <dl style="text-align: justify;margin-left: 33%;">
          <dt style="color: black;">Centro de ayuda</dt>
          <dd>
            <a class="zoom-social_icons-list__link" href="https://api.whatsapp.com/send?phone=59162076566"
              target="_blank">
              <i class="fab fa-whatsapp" style="color:#20B038;font-size: 20px;"></i>
              <span class="zoom-social_icons-list__label" style="color: black;font-size: 12px;"> Atención al Cliente
                62076566</span>
            </a>
          </dd>
          <dd><a rel="nofollow" target="_blank" href="https://www.facebook.com/suplementapp" class="fa fa-facebook"
              style="font-size: 25px;color:#3b5998"></a>
            <a rel="nofollow" target="_blank" href="https://www.facebook.com/" class="fa fa-instagram"
              style="color: #e4405f;padding: 1rem;font-size: 25px;"></a>
          </dd>

        </dl>
      </div>
      <div class="col-4">
        <dl style="text-align: justify;margin-left: 33%;">
          <dt style="color: black;">Categorias</dt>
          <dd *ngFor="let item of categories.slice(0,3);">
            <a rel="nofollow" style="color: black;font-size: 12px;" target="_blank">{{item.category_name}}</a>
          </dd>
          <dd>
            <a rel="nofollow" style="color: black;font-size: 12px;" target="_blank">Muchos mas</a>
          </dd>
        </dl>
      </div>
      <div class="col-4">
        <dl style="text-align: justify;margin-left: 33%;">
          <dt style="color: black;">Sobre nosotros</dt>
          <dd><a rel="nofollow" href="/politicas-confidencialidad" style="color: black;font-size: 12px;"
              target="_blank">Politicas de
              Confidencialidad</a>
          </dd>
          <dd><a rel="nofollow" href="/politicas-privacidad" style="color: black;font-size: 12px;"
              target="_blank">Politicas de
              privacidad</a>
          </dd>
          <dd><a rel="nofollow" href="/uso-de-cookies" style="color: black;font-size: 12px;" target="_blank">Uso de
              Cookies</a>
          </dd>
        </dl>
      </div>

    </div>
    <div class="row"
      style="background-color: #fafafa;padding-left: 13%;padding-right: 13%;padding-bottom: 2rem;padding-top: 2rem;border-bottom: 1px solid #313D52;">
      <div class="col-6" style="width: 50%;">
        Descargar:
        <a target="_blank" title="App Store" class="app-store">
          <img src="https://tiendaonlineapp.com/wp-content/uploads/revslider/home_wallet_button1.png" alt=""
            style="width: 6rem;margin-right: 1rem;"></a>
        <a target="_blank" title="Play Store" class="android"><img
            src="https://tiendaonlineapp.com/wp-content/uploads/revslider/home_wallet_button2.png" style="width: 6rem;"
            alt=""></a>
      </div>
      <div class="col-6" style="width: 50%;">
        Aceptamos pagos:
        <a target="_blank" title="App Store" href="{{url_ios}}" class="app-store">
          <img src="assets/cards.png" alt="" style="width: 60%;margin-right: 1rem;"></a>
      </div>

    </div>
    <div lass="row"
      style="background-color: #fafafa;padding-left: 13%;padding-right: 13%;padding-bottom: 0rem;padding-top: 1rem;">
      <div class="col-12">
        <a style="color:black;font-size: 12px;text-transform: uppercase;">SuplementApp © 2022</a>
      </div>
    </div>
  </div>
</footer>