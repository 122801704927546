<div class="container" style="padding-top: 1.5rem;padding-bottom: 2rem;">



    <div class="postcontent nobottommargin clearfix nosidebar">

        <h2 id="nombre-de-la-tienda-política-de-privacidad">Política de Privacidad</h2>
        <hr>
        <p>Esta Política de Privacidad describe cómo se recopila, utiliza y comparte tu información personal cuando
            visitas
            o realizas una compra en la tienda.</p>
        <p><strong>QUÉ INFORMACIÓN PERSONAL RECOPILAMOS</strong></p>
        <p>Cuando visitas el Sitio, recopilamos automáticamente cierta información sobre tu dispositivo, incluida
            información sobre tu navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas
            en
            tu dispositivo.</p>
        <p>Además, a medida que navegas por el Sitio, recopilamos información sobre las páginas web individuales o los
            productos que ves, qué sitios web o términos de búsqueda te remiten al Sitio, e información sobre cómo
            interactúas con el Sitio. Nos referimos a esta información recopilada automáticamente como
            <strong>Información
                del Dispositivo</strong>.
        </p>
        <p>Recopilamos información del dispositivo utilizando las siguientes tecnologías:</p>
        <ul>
            <li>
                <p><strong>Cookies</strong>: son archivos de datos que se colocan en tu dispositivo o computadora y con
                    frecuencia incluyen un identificador único anónimo.</p>
            </li>
            <li>
                <p><strong>Archivos de registro</strong>: rastrean las acciones que ocurren en el Sitio y recopilan
                    datos,
                    incluida tu dirección IP, el tipo de navegador, el proveedor de servicios de Internet, las páginas
                    de
                    referencia/salida y las marcas de fecha y hora.</p>
            </li>
        </ul>
        <p><em>Menciona todas las demás herramientas de seguimiento y/o tecnologías que usa tu sitio web.</em></p>
        <p>Además, cuando efectúas una compra o intentas realizar una compra a través del Sitio, recopilamos cierta
            información tuya, como tu nombre, dirección de facturación, dirección de envío, información de pago,
            incluidos
            números de tarjetas de crédito (<strong>menciona todos los tipos de pagos aceptados</strong>), dirección de
            email y el número de teléfono. Esto se denomina <strong>Información del Pedido</strong>.</p>
        <p><em>Asegúrate de mencionar toda otra información que recopiles.</em></p>
        <p>Al referirnos a <strong>Información Personal</strong> en esta Política de Privacidad, estamos hablando tanto
            de
            la Información del Dispositivo como de la Información del Pedido.</p>
        <p><strong>CÓMO USAMOS TU INFORMACIÓN PERSONAL</strong></p>
        <p>Utilizamos la Información de Pedido que recopilamos por lo general para cumplir con los pedidos realizados a
            través del Sitio (incluido el procesamiento de tu información de pago, la organización del envío y el envío
            de
            facturas y/o confirmaciones de pedidos).</p>
        <p>Además, usamos esta Información del Pedido para: comunicarnos contigo, examinar nuestros pedidos para
            detectar
            posibles riesgos o fraudes, para (en línea con las preferencias que has compartido con nosotros) ofrecerte
            información o publicidad relacionada con nuestros productos o servicios.</p>
        <p>Utilizamos la Información del Dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y
            fraudes
            (en particular, tu dirección IP) y, en general, para mejorar y optimizar nuestro sitio.</p>
        <p><strong>COMPARTIENDO TU INFORMACIÓN PERSONAL</strong></p>
        <p>Compartimos tu Información Personal con terceros para ayudarnos a utilizarla como se describió anteriormente.
        </p>
        <p>También empleamos Google Analytics para ayudarnos a comprender cómo nuestros clientes usan la tienda.

        </p>
        <p>Finalmente, también podemos compartir tu Información Personal para cumplir con las leyes y regulaciones
            aplicables, para responder a una citación, una orden de registro u otras solicitudes legales de información
            que
            recibimos, o para proteger nuestros derechos.</p>
        <p><strong>PUBLICIDAD DE COMPORTAMIENTO</strong></p>
        <p>Utilizamos tu Información Personal para proporcionarte anuncios específicos o comunicaciones de marketing que
            creemos que pueden ser de tu interés.</p>
        <p>
        <p>Puedes inhabilitar la publicidad dirigida…</p>
        <p><strong>TUS DERECHOS</strong></p>
        <p>Si eres un residente europeo, tienes derecho a acceder a la información personal que tenemos sobre ti y a
            solicitar que tu información personal se corrija, actualice o elimine. Si deseas ejercer este derecho, por
            favor
            contáctanos.</p>
        <p>Además, si eres un residente europeo, notamos que estamos procesando tu información para cumplir con los
            contratos que podríamos tener contigo (por ejemplo, si realizas un pedido a través del Sitio), o de otra
            manera
            para perseguir nuestros intereses comerciales legítimos mencionados anteriormente.</p>

    </div>
</div>