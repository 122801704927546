import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceHttpService } from './services/service-http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  session: any = { email: null, id: 0 };
  title = "titulo";
  count_card: number = 0;
  url_android: string = '';
  url_ios: string = "";
  categories: any[] = [];
  //comandos para publicar:
  //ng build --prod --base-href /carpeta/ --deploy-url /carpeta/
  //ng build --prod
  constructor(private service: ServiceHttpService, public router: Router) {
    this.getInfoData();
    this.url_android = service.URL_APP_ANDROID;
    this.url_ios = service.URL_APP_IOS;
  }
  getInfoData() {
    let email = this.service.getStorage("email_user_access");
    email.then((data) => {
      this.session.email = data == undefined || data == 'null' ? null : data;
    });
    let id_user_access = this.service.getStorage("id_user_access");
    id_user_access.then((data) => {
      this.session.id = data == undefined || data == 'null' ? null : data;
      if (this.session.email != null) {
        this.getCardProducts();
      }
    });
    this.getEnabledCategories();
  }
  sessionAccess() {
    if (this.session.email == null) {
      this.router.navigate(['/cuenta/inicio_sesion']);
    }
    else {
      this.router.navigate(['/cuenta']);
    }
  }

  goCard() {
    this.router.navigate(['/cuenta/carrito']);
  }
  closeSesion() {
    this.service.setStorage("email_user_access", null);
    this.service.setStorage("id_user_access", null);
    location.pathname = '/cuenta/inicio_sesion';
    //this.router.navigate(['/cuenta/inicio_sesion']);
  }
  async getEnabledCategories() {
    let content = this;
    this.categories = [];
    var request = this.service.getV1(this.service.GET_CATEGORY);
    request.subscribe((categorias: any[]) => {
      content.categories = categorias;
      categorias.forEach(async element => {
        var request_subca = await this.service.getV1(this.service.GET_SUBCATEGORY + element.category_id);
        request_subca.subscribe((subcategories: any[]) => {
          element.subcategories = subcategories;

        });
      });
    });
  }
  async getCardProducts() {
    let content = this;
    setInterval(async () => {

      var request = await content.service.getV1(content.service.GET_CARD_PRODUCTS + "&id_user_card_product=" + content.session.id);
      request.subscribe((data: any[]) => {
        content.count_card = data.length;
      });

    }, 3000);


  }
}
