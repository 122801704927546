import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubCategory } from 'src/app/models/subcategory.model';
import { ServiceHttpService } from 'src/app/services/service-http.service';

@Component({
  selector: 'app-subcategoria',
  templateUrl: './subcategoria.component.html',
  styleUrls: ['./subcategoria.component.scss']
})
export class SubcategoriaComponent implements OnInit {
  base_url: string;
  subCategories: SubCategory[] = [];
  category_id: number;
  category_name: string;
  constructor(private service: ServiceHttpService, public router: Router, private route: ActivatedRoute) {
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'visible';
    loading_image.style.position = 'initial';
    this.base_url = service.BASE_URL + "upload/subcategory/";
    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
    let s = this.route.url.subscribe((s2) => {
      try {
        this.category_id = Number(s2[2].path);
        this.category_name = s2[1].path;
      } catch (error) {
        this.category_id = 0;
      }

    });
  }

  ngOnInit(): void {
    this.subCategories = [];
    this.getCategories();
  }
  async getCategories() {
    let content = this;
    var request = await this.service.getV1(this.service.GET_SUBCATEGORY + this.category_id);
    request.subscribe((data: any[]) => {
      let loading_image = document.getElementById('loading_image') as any;
      loading_image.style.visibility = 'hidden';
      loading_image.style.position = 'absolute';
      window.scrollTo(0, 0);

      content.subCategories = data;
    });
  }
}
