<div class="container" style="padding-top: 5rem;">
    <div class="alert {{message_text.color}} alert-dismissible fade show" role="alert" *ngIf="message_text.visible">
        <strong *ngIf="message_text.color=='alert-warning'">Ups!</strong> {{message_text.text}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-primary text-white" style="background-color: #4285f4 !important"><i
                        class="fa fa-envelope"></i> Contáctanos.
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Nombre completo</label>
                        <input type="text" class="form-control" id="name" aria-describedby="emailHelp"
                            placeholder="Ingresa tu nombre">
                    </div>
                    <div class="form-group">
                        <label for="email">Correo</label>
                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                            placeholder="Ingresa tu correo">
                        <small id="emailHelp" class="form-text text-muted">Nunca compartiremos su correo electrónico
                            con nadie
                            demás.</small>
                    </div>
                    <div class="form-group">
                        <label for="message">Mensaje</label>
                        <textarea class="form-control" id="message" rows="6"></textarea>
                    </div>
                    <div class="mx-auto">
                        <button type="button" class="btn btn-primary text-right" (click)="sutbmit()">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="card bg-light mb-3" style="background-color: white !important;">
                <div class="card-header bg-success text-white" style="background-color: #4285f4 !important;"><i
                        class="fa fa-home"></i> Más Información
                </div>
                <div class="card-body">
                    <p>
                        <a class="zoom-social_icons-list__link" href="https://api.whatsapp.com/send?phone=59162076566"
                            target="_blank">
                            <i class="fab fa-whatsapp" style="color:#20B038;font-size: 25px;"></i>
                            <span class="zoom-social_icons-list__label" style="color: black;"> Atención al Cliente
                                62076566</span>
                        </a>
                    </p>
                    <div>
                        <h3 class="widget-title">Síguenos</h3>
                        <p><a rel="nofollow" target="_blank" href="https://www.facebook.com/suplementapp/"
                                class="fa fa-facebook" style="font-size: 25px;"></a>
                            <a rel="nofollow" target="_blank" href="https://www.facebook.com/" class="fa fa-instagram"
                                style="color: #e4405f;padding: 1rem;font-size: 25px;"></a>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>