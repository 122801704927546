<div class="container" style="padding-top: 1.5rem;padding-bottom: 2rem;">
    <h1 style="font-weight: 400;
        font-size: 28px;
        line-height: 1.2;">
        <b style="font-weight: 700;">{{category_name}}, {{subcategory_name}} , {{name_text}}</b>
    </h1>
    <hr>
    <div class="alert {{message_text.color}} alert-dismissible fade show" role="alert" *ngIf="message_text.visible">
        <strong *ngIf="message_text.color=='alert-warning'">Ups!</strong> {{message_text.text}}
        <a *ngIf="session.id_user_access == 0" class="alert-link mouse" routerLink="/cuenta/inicio_sesion">Acceder</a>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="card">
        <div class="row no-gutters">
            <aside class="col-sm-6 border-right">
                <article class="gallery-wrap" style="text-align-last: center;padding-top: 1rem;">
                    <div class="img-big-wrap">
                        <a><img [src]="product.product_image"></a>
                    </div> <!-- img-big-wrap.// -->
                    <div class="thumbs-wrap">
                        <a (click)="changeImage(item)" class="item-thumb" *ngFor="let item of images_products">
                            <img src="{{item.url_product}}"></a>
                    </div> <!-- thumbs-wrap.// -->
                </article> <!-- gallery-wrap .end// -->
            </aside>
            <main class="col-sm-6">
                <article class="content-body">
                    <h2 class="title">{{product.product_name}}</h2>

                    <p [innerHTML]="product.product_description"></p>

                    <p class="text-muted small mouse"><a *ngIf="product.product_video" data-toggle="modal"
                            style="cursor: pointer;" data-target="#myModal" (click)="changeVideo()">Ver video</a></p>

                    <div class="h3 mb-4">
                        <var class="price h4">Precio: {{product.product_price}} Bs</var>
                    </div> <!-- price-wrap.// -->

                    <div class="form-row">
                        <div class="col">
                            <div class="form-group col-md flex-grow-0">
                                <div class="input-group input-spinner">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-light" type="button" (click)="plus()"> + </button>
                                    </div>
                                    <input type="text" class="form-control" id="count_item" value="1">
                                    <div class="input-group-append">
                                        <button class="btn btn-light" type="button" (click)="minus()"> − </button>
                                    </div>
                                </div> <!-- input-spinner.// -->
                            </div>
                        </div>
                        <div class="col">
                            <button (click)="addCard()" class="btn btn-primary w-100"> Agregar al carrito <i
                                    class="fas fa-shopping-cart"></i>
                            </button>
                        </div>
                        <div class="col" hidden>
                            <a href="#" class="btn  btn-light"> <i class="fas fa-heart"></i> </a>
                        </div>
                    </div>

                </article>
            </main>
        </div>
    </div>
    <h1 style="padding:1rem;font-weight: 400;
            font-size: 28px;
            line-height: 1.2;">
        <b style="font-weight: 700;">Otros productos que te pueden interesar</b>
    </h1>
    <div align="center" style="width: 100%; height: auto; text-align:center;margin:0; padding:0;">
        <ng-image-slider #nav [images]="products" [infinite]="true" [imagePopup]="false"
            [autoSlide]="{interval: 5, stopOnHover: false}" (imageClick)="slider($event)"
            [imageSize]="{width: '25%',space: 3}" slideImage="1">
        </ng-image-slider>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">


                <div class="modal-body">

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <!-- 16:9 aspect ratio -->
                    <div class="embed-responsive embed-responsive-16by9" id="videoplay">
                        <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always"
                            allow="autoplay"></iframe>
                    </div>


                </div>

            </div>
        </div>
    </div>
</div>