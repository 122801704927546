import { Component, OnInit } from '@angular/core';
import { ServiceHttpService } from 'src/app/services/service-http.service';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {
  products: any[] = [];
  products_temporal: any[];
  base_url: string = '';
  products_show: any[] = [];
  active = false;
  products_tem_show = [];
  input_text: string = '';
  constructor(private service: ServiceHttpService) {
    let loading_image = document.getElementById('loading_image') as any;
    loading_image.style.visibility = 'visible';
    loading_image.style.position = 'initial';
    window.scrollTo(0, 0);
    this.base_url = service.BASE_URL + "upload/product/";
    let footer = document.getElementById('footer') as any;
    footer.style.position = 'absolute';
  }

  ngOnInit(): void {
    this.reload();
    let buscar_id = document.getElementById('buscar_id') as any;
    setInterval(async () => {
      if (this.input_text == buscar_id.value) {
        this.active = false;

      }
      else {
        this.active = true;
        this.searchProducts(buscar_id.value);

      }
      this.searchProductsShow(buscar_id.value);
    }, 1000);
  }
  reload() {
    var context = this;
    let products_temporal: any = this.service.getV1(this.service.GET_PRODUCTOS_RECIENTES + '&page=' + 1000);
    products_temporal.subscribe(
      res => {
        context.products_temporal = [];
        this.products_tem_show = res;
        res.forEach(element => {
          context.products_temporal.push({ tag: element.product_name });
          context.products_temporal.push({ tag: element.category_name });
          let tags = element.tag.split(',');
          tags.forEach(item => {
            context.products_temporal.push({ tag: item });
          });
          let loading_image = document.getElementById('loading_image') as any;
          loading_image.style.visibility = 'hidden';
          loading_image.style.position = 'absolute';
          window.scrollTo(0, 0);
        });
      });
  }

  searchProducts(ev) {
    const value = (ev).toLowerCase();
    if (value && value.trim() != '') {
      this.products = [...this.products_temporal].filter((item) => {
        return (item.tag.toLowerCase().indexOf(value) > -1);
      });
    }
    else {
      this.products = [];
      this.active = false;
    }
  }
  searchProductsShow(value) {
    value = (value).toLowerCase();
    if (value.trim() != '') {
      this.products_show = [...this.products_tem_show].filter((item) => {
        return (item.category_name.toLowerCase().indexOf(value) > -1) ||
          (item.product_description.toLowerCase().indexOf(value) > -1) ||
          (item.product_name.toLowerCase().indexOf(value) > -1) ||
          (item.tag.toLowerCase().indexOf(value) > -1);
      });
    }
    else {
      this.products_show = [...this.products_tem_show];
    }
  }
  tag(item) {
    this.active = false;
    let tag = item.tag;
    this.input_text = tag;
    this.products = [];
    let content = document.getElementById('buscar_id') as any;
    content.value = tag;
  }
}
