<div class="container" style="padding-top: 1.5rem;padding-bottom: 2rem;">
    <div class="qc-cmp2-summary-info qc-cmp2-logo-displayed">
        <h2 id="nombre-de-la-tienda-política-de-privacidad">Uso de Cookies</h2>
        <hr>
        <div>Nosotros y nuestros
            almacenamos o accedemos a información en un dispositivo, tales como cookies, y procesamos datos personales,
            tales como identificadores únicos e información estándar enviada por un dispositivo, para anuncios y
            contenido
            personalizados, medición de anuncios y del contenido e información sobre el público, así como para
            desarrollar y
            mejorar productos.</div>
        <div>Con su permiso, nosotros y nuestros socios podemos utilizar datos de localización geográfica precisa e
            identificación mediante las características de dispositivos. Puede hacer clic para otorgarnos su
            consentimiento
            a nosotros y a nuestros socios para que llevemos a cabo el procesamiento previamente descrito. De forma
            alternativa, puede acceder a información más detallada y cambiar sus preferencias antes de otorgar o negar
            su
            consentimiento.</div>
        <div>Tenga en cuenta que algún procesamiento de sus datos personales puede no requerir de su consentimiento,
            pero
            usted tiene el derecho de rechazar tal procesamiento. Sus preferencias se aplicarán solo a este sitio web.
            Puede
            cambiar sus preferencias en cualquier momento entrando de nuevo en este sitio web o visitando nuestra
            política
            de privacidad.</div>
    </div>

</div>