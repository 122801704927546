import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from "@angular/common";
import { SubcategoriaComponent } from './pages/categories/subcategoria/subcategoria.component';
import { ProductsComponent } from './pages/categories/products/products.component';
import { ProductDetailComponent } from './pages/categories/product-detail/product-detail.component';
import { BuscadorComponent } from './pages/categories/buscador/buscador.component';
import { ContactComponent } from './pages/categories/contact/contact.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { PoliticasConfidencialidadComponent } from './pages/categories/politicas-confidencialidad/politicas-confidencialidad.component';
import { PoliticasPrivacidadComponent } from './pages/categories/politicas-privacidad/politicas-privacidad.component';
import { UsoCookiesComponent } from './pages/categories/uso-cookies/uso-cookies.component';
@NgModule({
  declarations: [
    AppComponent,
    SubcategoriaComponent,
    ProductsComponent,
    ProductDetailComponent,
    BuscadorComponent,
    ContactComponent,
    PoliticasConfidencialidadComponent,
    PoliticasPrivacidadComponent,
    UsoCookiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CommonModule,
    NgImageSliderModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
