<div class="container">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3 mouse" *ngFor="let item of subCategories; let i = index"
            routerLink='/categorias/{{category_name}}/{{item.name}}/productos/{{item.sub_category_id}}'>
            <div class="card mb-3">
                <img class="card-img-top" src="{{base_url}}{{item.sub_category_image}}" alt="{{item.name}}">
                <div class="card-body">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p class="card-text"></p>
                </div>
            </div>
        </div>

    </div>
</div>