<div class="container" style="padding-top: 1.5rem;padding-bottom: 2rem;">
    <h1 style="font-weight: 400;
    font-size: 28px;
    line-height: 1.2;">
        <b style="font-weight: 700;">{{category_name}}, {{sub_category_name}} , {{name_text}}</b>
    </h1>
    <hr>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3 mouse" *ngFor="let item of products;" (click)="gotoProduct(item)">
            <div class="card mb-3">
                <img class="card-img-top" src="{{base_url}}{{item.product_image}}" alt="{{item.product_name}}"
                    style="height: 15rem;">
                <div class="card-body">
                    <h5 class="card-title" style="height: 3rem;">
                        {{item.product_name.slice(0,(item.product_name.length>39?39:item.product_name.length))}}</h5>
                    <p class="card-text">Precio: <strong>{{item.product_price}} {{item.currency_code}}</strong> </p>

                </div>
            </div>
        </div>

    </div>

</div>